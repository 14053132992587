import MainPage from 'views/MainPage';
import Profile from 'views/Profile';

// project imports
// import MainLayout from 'layout/MainLayout';
// import Loadable from 'ui-component/Loadable';

// dashboard routing
// const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
// const Profile = Loadable(lazy(() => import('views/Profile')));
// const MainPage = Loadable(lazy(() => import('views/MainPage')));
// const Pismo = Loadable(lazy(() => import('views/Pismo')));
// const Neon = Loadable(lazy(() => import('views/Neon')));

// // utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));

// const SleevePismo = Loadable(lazy(() => import('views/sleeve/Pismo')));
// const SleeveNeon = Loadable(lazy(() => import('views/sleeve/Neon')));

// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// // sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <Profile />,
    children: [
        {
            path: '/',
            element: <MainPage />
        }
    ]
};

export default MainRoutes;
