import { Link } from "react-router-dom";

// material-ui
import { ButtonBase } from "@mui/material";

import esaflogo from "../../../assets/esaf-full-white-logo.png";

// project imports
import config from "config";
import Logo from "ui-component/Logo";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  // <ButtonBase disableRipple component={Link} to={config.defaultPath}>
  <div component={Link} to="/">
    {/* <Logo /> */}
    <img
      src={esaflogo}
      alt="utkarsh logo"
      style={{ display: "block", height: "56px", width: "140px" }}
    />
  </div>
);

export default LogoSection;
