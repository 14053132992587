import React from "react";
import { CircularProgress, Grid } from "@mui/material";
import {
  View,
  Image,
  TextField,
  Button,
  PasswordField,
  Alert,
} from "@aws-amplify/ui-react";
import utkbLogo from "../../assets/utkarsh-logo1.png";
import esaflogo from "../../assets/esaf-logo.png";
import esaflogo2 from "../../assets/headloginLogoNew2.png";
import EmailIcon from "@mui/icons-material/Email";
import HttpsIcon from "@mui/icons-material/Https";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import * as yup from "yup";
import { genErrors } from "utils/Functions";
import { Auth } from "aws-amplify";
import ForgotPassword from "./ForgotPassword";
import { useEffect } from "react";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
} from "react-simple-captcha";

import Package from "../../../package.json";
import EnableCookies from "./EnableCookies";
import { height, width } from "@mui/system";

const source = Package?.name;

const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

const schema = yup.object().shape({
  username: yup
    .string()
    .required("Email is required")
    .email("Email is invalid"),
  password: yup.string().required("Password is required"),
  captcha: yup
    .string()
    .required("Captcha is Required")
    .test("Captcha", "Invalid Captcha please try again", (value) => {
      try {
        return validateCaptcha(value) == true;
      } catch (error) {
        return false;
      }
    }),
});

const initialState = {
  username: "",
  password: "",
  captcha: "",
};

export default function Login() {
  const [userData, setUserdata] = useState({
    ...initialState,
  });
  const [errors, setErrors] = useState({
    ...initialState,
  });
  const [errorMessage, setErrorMessage] = useState({ code: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState(null);
  const captchaRef = React.useRef();
  const [hasAccessToCookies, setHasAccessToCookies] = useState(null);

  const onChange = (e) => {
    setUserdata({ ...userData, [e.target.name]: e.target.value });
  };

  // const handleCaptchaChange = (value) => {
  //     setCaptcha(value);
  //     setErrorMessage({ code: '', message: '' });
  // };

  const resetCapthca = () => {
    validateCaptcha(null);
    setUserdata((prev) => {
      return { ...prev, captcha: "" };
    });
  };

  useEffect(() => {
    if (hasAccessToCookies === true || hasAccessToCookies === null) return;
    const browser = getBrowserName();
    setErrorMessage((prev) => {
      return {
        ...prev,
        message: `Please Enable Cookies \n
            1. Go to Settings.
            ${
              browser == "Chrome"
                ? `2. Go to Privacy and Security.
             3. Go to Third Party Cookies.
             4. Select Allow Third Party Cookies.
             5. Refresh the Page to see the changes.`
                : browser == "Firefox"
                ? `2. Go to Privacy and security. \n
             3. Choose Standard.`
                : browser == "Safari"
                ? `2. Open Safari Setting.
             3. Turn off the Block All Cookies.`
                : browser == "Edge"
                ? `2. Go to Cookies and site permissions.
             3. Go to Cookies and data stored/Cookies and site data.
             3. Go to Manage and delete cookies and site data.
             3. Allow sites to save and read cookie data (recommended).`
                : `2. Go to Privacy and security
             3. Enable Cookies.`
            }`,
      };
    });
  }, [hasAccessToCookies]);

  function getBrowserName() {
    var userAgent = navigator.userAgent;

    // Microsoft Internet Explorer detected as IE
    if (/MSIE/.test(userAgent) || /Trident/.test(userAgent)) {
      return "IE";
    }
    // Edge 20+
    else if (/Edge\/\d+/.test(userAgent)) {
      return "Edge";
    }
    // Google Chrome (and browsers that use the Blink engine, but this will label them as Chrome)
    else if (/Chrome/.test(userAgent)) {
      return "Chrome";
    }
    // Firefox
    else if (/Firefox/.test(userAgent)) {
      return "Firefox";
    }
    // Safari
    else if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
      return "Safari";
    }
    // Default to unknown
    else {
      return "Unknown";
    }
  }

  useEffect(() => {
    // Validation For Cookes Checking
    try {
      const localStorage = window.localStorage;
      const sessionStorage = window.sessionStorage;
      setHasAccessToCookies(true);
    } catch (error) {
      setHasAccessToCookies(false);
      return;
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    // Validation check
    if (!(await validate())) return;
    // Captcha check
    // if (!!!captcha) {
    //     setErrorMessage({ code: 'captchaError', message: 'Please verify captcha' });
    //     return;
    // }
    if (validateCaptcha(userData.captcha) == false) {
      setUserdata((prev) => {
        return { ...prev, captcha: "" };
      });
      validateCaptcha(null);
      return;
    }
    setLoading(true);
    await Auth.signIn(userData.username, userData.password, {
      recaptcha: captcha,
      source,
    }).catch((err) => {
      if (
        err.code === "NotAuthorizedException" ||
        err.code === "UserNotFoundException"
      )
        err.message =
          "The password or email entered is incorrect. Please try again.";
      setErrorMessage({ code: err.code, message: err.message });
    });
    setLoading(false);
    // captchaRef.current.reset();
    resetCapthca();
  };

  const validate = async () => {
    let result = await genErrors(schema, userData, { ...initialState });
    if (!result.status) {
      setErrors(result.errors);
      return false;
    }
    setErrors({ ...initialState });
    return true;
  };

  useEffect(() => {
    if (errorMessage.code === "") resetCapthca();
  }, [errorMessage.code]);

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  useEffect(() => {
    // "NotAuthorizedException"
    if (
      userData.username === "" ||
      (userData.password === "" &&
        (errorMessage.code === "NotAuthorizedException" ||
          errorMessage.code === "UserNotFoundException"))
    ) {
      setErrorMessage({ code: "", message: "" });
    }
  }, [userData]);

  return (
    <Grid
      container
      item
      justifyContent="center"
      alignItems="center"
      direction="row"
      alignContent="center"
      className="Login_container"
    >
      <EnableCookies isCookiesEnabled={hasAccessToCookies} />
      {errorMessage.code === "PasswordResetRequiredException" ? (
        <ForgotPassword onSetErrorMessage={setErrorMessage} />
      ) : (
        <Grid item className="Login_box" direction="column">
          <View
            textAlign="center"
            className="utkarshlogo"
            style={{
              paddingBottom: "0px",
              paddingTop: "0px",
              height: "115px",
              width: "420px",
            }}
          >
            <Image
              src={esaflogo2}
              alt="utkarshlogo"
              style={{ width: "100%", height: "100%" }}
            />
          </View>
          <form onChange={onChange} onSubmit={onSubmit}>
            <View className="Login_siginIn">
              <Grid item container>
                <TextField
                  label="Email"
                  name="username"
                  value={userData.username}
                  autoFocus="true"
                  autoComplete="off"
                  aria-autocomplete="none"
                  className="Login_input"
                  innerStartComponent={
                    <div className="Login_input_innerStartComponent">
                      <EmailIcon />
                    </div>
                  }
                  type="text"
                  errorMessage={errors.username}
                  hasError={errors.username !== ""}
                />
              </Grid>
              <Grid item container className="Login_pwd_container">
                <PasswordField
                  label="Password"
                  value={userData.password}
                  className="Login_input"
                  autoComplete="off"
                  aria-autocomplete="none"
                  innerStartComponent={
                    <div className="Login_input_innerStartComponent">
                      <HttpsIcon />
                    </div>
                  }
                  name="password"
                  errorMessage={errors.password}
                  hasError={errors.password !== ""}
                />
              </Grid>
              <Grid item container>
                <TextField
                  label="Captcha"
                  name="captcha"
                  value={userData.captcha}
                  aria-autocomplete="none"
                  className="Login_input"
                  type="text"
                  errorMessage={errors.captcha}
                  hasError={errors.captcha !== ""}
                />
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                className="Login_signInContainer"
              >
                {/* <ReCAPTCHA
                                    ref={captchaRef}
                                    sitekey={siteKey}
                                    onChange={handleCaptchaChange}
                                    onExpired={() => setCaptcha(null)}
                                /> */}
                <div className="Login_Recaptcha">
                  <LoadCanvasTemplate />
                </div>
              </Grid>
              <Grid item container className="Login_messageBox">
                {errorMessage?.message !== "" && (
                  <Alert
                    width={"100%"}
                    variation="error"
                    onDismiss={() => {
                      setErrorMessage({ code: "", message: "" });
                    }}
                    isDismissible={true}
                    hasIcon={true}
                  >
                    {errorMessage?.message}
                  </Alert>
                )}
              </Grid>
              <Grid item container className="">
                <Button
                  type="submit"
                  className={loading ? "Login_signIn_Loading" : "Login_signIn"}
                >
                  {loading ? (
                    <>
                      <CircularProgress size={15} color="inherit" /> &nbsp;{" "}
                      <span>Signing In</span>
                    </>
                  ) : (
                    "Sign In"
                  )}
                </Button>
              </Grid>

              <Grid
                item
                container
                direction="row"
                justifyContent="center"
                className="Login_signInContainer"
              >
                <label
                  onClick={() => {
                    setErrorMessage({
                      code: "PasswordResetRequiredException",
                      message: "",
                    });
                  }}
                  className="Login_forgot_pwd"
                >
                  Forgot Password
                </label>
              </Grid>
            </View>
          </form>
        </Grid>
      )}
    </Grid>
  );
}
