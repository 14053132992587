import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { LinearProgress } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    iframeContainer: {
        width: '100%',
        height: '84vh',
        border: 'none'
    }
}));

const MainPage = () => {
    const [encReq, setEncReq] = useState('');
    const [JWT, setJWT] = useState(null);
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const classes = useStyles();

    useEffect(() => {
        Auth.currentAuthenticatedUser({ bypassCache: true })
            .then(async (result) => {
                let session = await Auth.currentSession().catch((e) => null);
                let JWT = session?.accessToken?.jwtToken;
                setJWT(JWT);

                setEncReq(result['attributes']['custom:documentID']);
            })
            .catch((error) => console.log('error', error));
    }, []);
    let IFRAME_URL = `${process.env.REACT_APP_ACCOUNT_HOLDER_URL}/#?encReq=${encReq}&clientId=${clientId}&JWT=${JWT}`;
    return (
        <>
            {encReq && encReq !== '' && !!JWT ? (
                <iframe className={classes.iframeContainer} src={IFRAME_URL} allowFullScreen title="Account Holder App"></iframe>
            ) : (
                <LinearProgress />
            )}
        </>
    );
};
export default MainPage;
