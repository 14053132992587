import { useState, useRef, useEffect } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Chip,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  Typography,
} from "@mui/material";

// third-party

// project imports
import MainCard from "ui-component/cards/MainCard";
import Transitions from "ui-component/extended/Transitions";
import PersonIcon from "@mui/icons-material/Person";
import ExitToApp from "@mui/icons-material/ExitToApp";
// assets
import { IconSettings } from "@tabler/icons";

import { Auth } from "aws-amplify";

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  // Amplify signout
  const signOut = (e) => {
    e.preventDefault();
    Auth.signOut();
  };

  useEffect(() => {
    if (open === true)
      document
        .getElementsByTagName("iframe")[0]
        ?.addEventListener("mouseover", (e) => {
          setOpen(false);
        });
    else {
      document
        .getElementsByTagName("iframe")[0]
        ?.removeEventListener("mouseover", () => {});
    }
  }, [open]);

  return (
    <>
      <Chip
        sx={{
          height: "37px",
          width: "38px",
          alignItems: "center",
          borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor: "#06377a",
          backgroundColor: "#06377a",
          color: "white",
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: "white",
            background: "#06377a",
            color: "white",
            "& svg": {
              stroke: theme.palette.primary.light,
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
          "& .MuiChip-icon": {
            marginLeft: "15px",
          },
        }}
        icon={<ExitToApp />}
        label={""}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}
      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2 }}>
                    <List
                      component="nav"
                      sx={{
                        paddingTop: "0px",
                        paddingBottom: "0px",
                      }}
                    >
                      <ListItemButton>
                        <ListItemText
                          onClick={signOut}
                          primary={<Typography>Sign out</Typography>}
                        />
                      </ListItemButton>
                    </List>
                  </Box>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>
  );
};

export default ProfileSection;
