import React, { useState, useEffect } from 'react';
import utkarshlogo from '../../assets/utkarsh-logo.png';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import './Userinfo.css';
import { useLocation } from 'react-router-dom';
const UserInfo = () => {
    const location = useLocation();
    const { pathname } = location;
    const [source, setSource] = useState('');

    useEffect(() => {
        if (pathname === '/smslanding/info') {
            setSource('phone');
        } else {
            setSource('');
        }
    }, [pathname]);
    if (pathname != '') {
        localStorage.setItem('source', source);
    }
    return (
        <div className="container">
            <div className="link_header">
                <img src={utkarshlogo} alt="utkarsh logo" className="link_utkarsh_logo" />
                <div className="login_button_container">
                    <Link to="/" className="link_login">
                        Login
                    </Link>
                </div>
            </div>
            <div className="grid-background">
                <Grid container spacing={2} className="grid-container">
                    <Grid item xs={12} md={6}>
                        <div className="grid-item side_heading">
                            <div className="welome_bank">Welcome to</div>
                            <div className="bank_name">Utkarsh Bank Credit Card</div>
                        </div>
                        <div className="options_selected">
                            <button className="inner_option_button">Password Setup</button>
                            <button className="inner_option_button inner_option_position">Card Activation</button>
                            <button className="inner_option_button inner_option_position">Need Help ?</button>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={1}></Grid>
                    <Grid item xs={12} md={5}>
                        <div className="grid-item descrption_box"></div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default UserInfo;
