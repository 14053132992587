import moment from 'moment';
import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import TimeOut from './TimeOut';

const timeoutLimit = process.env.REACT_APP_TIME_OUT; //10 Minutes

export default function TimeoutTimer() {
    const [timer, setTimer] = React.useState('');
    const [open, setOpen] = useState(false);
    const intervalRef = useRef();
    React.useEffect(() => {
        startTimer();
        return () => clearInterval(intervalRef.current);
    }, []);

    const startTimer = () => {
        intervalRef.current = setInterval(() => {
            if (isValidSession()) {
                let timeOutWithAfter10min = moment(localStorage.getItem('timeOut')).add(timeoutLimit, 'milliseconds');
                var mins = moment.utc(moment(timeOutWithAfter10min, 'HH:mm:ss').diff(moment(new Date(), 'HH:mm:ss'))).format('mm:ss');
                setTimer(mins);
            }
        }, 1000);
    };

    const isValidSession = () => {
        let currentTime = moment();
        let timeOut = moment(localStorage.getItem('timeOut'));
        let timeOutWithAfter10min = moment(timeOut).add(timeoutLimit, 'milliseconds');
        if (!currentTime.isBetween(timeOut, timeOutWithAfter10min)) {
            clearInterval(intervalRef.current);
            setOpen(true);
            return false;
        }
        return true;
    };

    const keepMeLoggedIn = () => {
        setOpen(false);
        localStorage.removeItem('timeOut');
        localStorage.setItem('timeOut', new Date());
        startTimer();
    };

    return (
        <>
            <TimeOut open={open} setOpen={setOpen} keepMeLoggedIn={keepMeLoggedIn} />
            <div style={{ color: 'white' }}>Session Time : {timer}</div>
        </>
    );
}
