import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import UserInfo from 'components/UserInfo/UserInfo';
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([MainRoutes, { path: '/smslanding/info', element: <UserInfo /> },
    {path:'/phone',element:<UserInfo/>}]);
}
