import React from 'react';
import { Auth, Hub } from 'aws-amplify';
import { useState } from 'react';
import Login from './Login';
import { LinearProgress } from '@mui/material';
import { useEffect } from 'react';
import './Auth.scss';

export default function CustomAuthenticator({ children }) {
    const [status, setStatus] = useState(null);

    useEffect(() => {
        Auth.currentAuthenticatedUser({
            bypassCache: true
        })
            .then((data) => setStatus('signIn'))
            .catch((err) => setStatus('signOut'));
        setStatus(status);
        Hub.listen('auth', (data) => {
            let events = ['signIn', 'signOut'];
            if (events.includes(data.payload.event)) setStatus(data.payload.event);
        });
    }, []);

    if (status === null) return <LinearProgress />;

    if (status === 'signIn') return children;

    if (status === 'signOut') return <Login />;
    else
        return (
            <center>
                <h1>{status}</h1>
            </center>
        );
}
