import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #005aaa",
  p: 4,
};

const steps = {
  marginBottom: "5px",
};

export default function EnableCookies({ isCookiesEnabled }) {
  function getBrowserName() {
    var userAgent = navigator.userAgent;

    // Microsoft Internet Explorer detected as IE
    if (/MSIE/.test(userAgent) || /Trident/.test(userAgent)) {
      return "IE";
    }
    // Edge 20+
    else if (/Edge\/\d+/.test(userAgent)) {
      return "Edge";
    }
    // Google Chrome (and browsers that use the Blink engine, but this will label them as Chrome)
    else if (/Chrome/.test(userAgent)) {
      return "Chrome";
    }
    // Firefox
    else if (/Firefox/.test(userAgent)) {
      return "Firefox";
    }
    // Safari
    else if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
      return "Safari";
    }
    // Default to unknown
    else {
      return "Unknown";
    }
  }

  const browser = getBrowserName();

  return (
    <div>
      <Modal open={!isCookiesEnabled}>
        <Box sx={style} className="no-cookies-modal">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#005aaa",
              flexDirection: "row",
              justifyContent: "start",
              padding: 10,
            }}
          >
            {" "}
            {/* Flex container */}
            <WarningAmberOutlinedIcon
              color="#005aaa"
              style={{ marginRight: 10 }}
            />
            <Typography variant="subtitle1" color={"#673ab7"}>
              Please enable cookies to continue
            </Typography>{" "}
            {/* Your text here */}
          </div>
          <div className="error-onload-div">
            <div className="error-onload-div2">
              <div
                style={{
                  marginLeft: "45px",
                  color: "#005aaa",
                  marginRight: "30px",
                }}
              >
                <div style={{ marginBottom: "10px" }}>
                  We use cookies to refine your experience. Once you have
                  enabled cookies in your browser, please try again.
                </div>
                <div style={steps}>1. Go to Settings.</div>
                {browser == "Chrome" ? (
                  <>
                    <div style={steps}>2. Go to Privacy and Security.</div>
                    <div style={steps}>3. Go to Third Party Cookies.</div>
                    <div style={steps}>
                      4. Select Allow Third Party Cookies.
                    </div>
                    <div style={steps}>
                      5. Refresh the Page to see the changes.
                    </div>
                  </>
                ) : browser == "Firefox" ? (
                  <>
                    <div style={steps}>2. Go to Privacy and security.</div>
                    <div style={steps}>3. Choose Standard.</div>
                  </>
                ) : browser == "Safari" ? (
                  <>
                    <div style={steps}>2. Open Safari Setting.</div>
                    <div style={steps}>3. Turn off the Block All Cookies.</div>
                  </>
                ) : browser == "Edge" ? (
                  <>
                    <div style={steps}>
                      2. Go to Cookies and site permissions.
                    </div>
                    <div style={steps}>
                      3. Go to Cookies and data stored/Cookies and site data.
                    </div>
                    <div style={steps}>
                      3. Go to Manage and delete cookies and site data.
                    </div>
                    <div style={steps}>
                      3. Allow sites to save and read cookie data (recommended).
                    </div>
                  </>
                ) : (
                  <>
                    <div style={steps}>2. Go to Privacy and security.</div>
                    <div style={steps}>3. Enable Cookies.</div>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* <div>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            We use cookies to refine your experience. Once you have enabled cookies in your browser, please try again.
                        </Typography>
                    </div> */}
        </Box>
      </Modal>
    </div>
  );
}
