import { useSelector } from 'react-redux';

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';

import React from 'react';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { Hub } from 'aws-amplify';
import { CssBaseline } from '@mui/material';

// ==============================|| APP ||============================== //

const App = () => {
    console.log('Dummy Deployment');
    const customization = useSelector((state) => state.customization);
    Hub.listen('auth', (data) => {
        const { payload } = data;
        if (payload.event === 'signOut') {
            localStorage.clear();
            sessionStorage.clear();
        }
    });

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
