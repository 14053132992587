import { Amplify } from 'aws-amplify';

// eslint-disable-next-line no-unused-vars
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { View, Image, useTheme, Text } from '@aws-amplify/ui-react';

import EmailIcon from '@mui/icons-material/Email';
import HttpsIcon from '@mui/icons-material/Https';

// eslint-disable-next-line no-unused-vars
import Mainlayout from '../layout/MainLayout/index';

import utkarshlogos from '../assets/utkarsh-logo1.png';

import '../App.css';

import AuthWrapper1 from './pages/authentication/AuthWrapper1';
import TimeoutWrapper from 'components/Wrappers/TimeoutWrapper';
import CustomAuthenticator from 'components/CustomAuthenticator/CustomAuthenticator';

const components = {
    SignIn: {
        Header() {
            const { tokens } = useTheme();

            return (
                <>
                    <View textAlign="center" className="utkarshlogo" style={{ paddingBottom: '70px', paddingTop: '50px' }}>
                        <Image src={utkarshlogos} alt="utkarshlogo" />
                    </View>
                </>
            );
        },
        Footer() {
            const { toResetPassword } = useAuthenticator();
            return (
                <View textAlign="center" style={{ paddingBottom: '20px', paddingTop: '20px' }}>
                    <Text fontWeight="normal" onClick={toResetPassword} size="small" variation="link">
                        <span className="forgotpassword">Forgot Password</span>
                    </Text>
                </View>
            );
        }
    }
};

export const formFields = {
    signIn: {
        username: {
            label: 'eMail',
            placeholder: '',
            isRequired: true,
            style: {
                border: 'none',
                color: '#000 !important'
            },
            innerStartComponent: (
                <div style={{ padding: '15px 8px 0px 8px' }}>
                    <EmailIcon style={{ color: '#000' }} />
                </div>
            )
        },
        password: {
            label: 'Password',
            placeholder: '',
            isRequired: true,
            style: {
                border: 'none',
                color: '#000 !important'
            },
            innerStartComponent: (
                <div style={{ padding: '15px 8px 0px 8px' }}>
                    <HttpsIcon style={{ color: '#000' }} />
                </div>
            )
        }
    }
};

export default function Profile() {
    return (
        <div className="authStyle">
            <AuthWrapper1>
                <CustomAuthenticator>
                    <TimeoutWrapper>
                        <main style={{ border: '5px solid red !important' }}>
                            <Mainlayout />
                            {/* <button onClick={signOut} style={{ display: 'block' }}>
                            Sign out
                        </button> */}
                        </main>
                    </TimeoutWrapper>
                </CustomAuthenticator>
            </AuthWrapper1>
        </div>
    );
}
